<template>
  <div>
    <center>
      <div class="mb-4">
        <img
          src="https://d3oia8etllorh5.cloudfront.net/us-east-1_dM0EI6uHe/ALL/20211208193832/assets/images/image.jpg"
        />
      </div>
      <v-alert icon="mdi-login" prominent text type="info">
        <v-row align="center" no-gutters>
          <v-col class="grow">
            <h2 class="font-weight-light" v-if="errorMessage == null">
              Logging in...
            </h2>
            <div
              style="width: 500px"
              class="font-weight-light"
              v-if="errorMessage !== null"
            >
              {{ errorMessage }}
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="shrink">
            <v-progress-circular
              class="ml-n12"
              :size="30"
              :width="5"
              color="primary"
              indeterminate
              v-if="errorMessage == null"
            ></v-progress-circular>
            <v-btn
              v-if="errorMessage !== null"
              color="white"
              plain
              outlined
              href="init-login"
              >Login</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </center>
  </div>
</template>

<script>
import { completeLogin } from "@/clients/auth";
import { tlMe } from "@/clients/tracelight";

import router from "@/router";

export default {
  name: "Login",
  data: () => ({
    errorMessage: null,
  }),
  mounted() {
    const params = new URLSearchParams(window.location.search);
    if (params.get("error") !== null) {
      const errorMsg = params.get("error");
      const errorDescription = params.get("error_description");
      this.errorMessage = `${errorMsg} - ${decodeURI(errorDescription)}`;
      return;
    }
    if (params.get("state") !== null) {
      this.errorMessage = null;
      const payload = {
        code: params.get("code"),
        state: params.get("state"),
        redirect_uri: `${window.location.origin}/login`,
      };

      completeLogin(payload)
        .then(() => {
          const nextRoute = window.localStorage.getItem("nextUrl");
          if (nextRoute) {
            if (nextRoute.includes("/login?code=")) {
              router.push("/");
            } else {
              router.push(nextRoute);
            }
          } else {
            router.push("/");
          }
        })
        .catch(function (error) {
          console.error(error);
          return;
        });
    }
  },
  computed: {
    loginUrl: function () {},
    loginInit: function () {
      return;
    },
  },
  methods: {},
};
</script>
